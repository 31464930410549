import React from "react";
import EventImage from "../pics/beacon-event.jpeg";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div id="about-us" className="section-components">
      <h1 className="about-title">About Us</h1>
      <div className="img-container">
        <img src={EventImage} alt="err"></img>
      </div>
      <p>
        The Beacon Hub is an organisation dedicated to making a
        meaningful difference in our community. Established with a commitment to
        advancing the Islamic faith, we strive to create a welcoming environment
        where individuals from all walks of life can benefit from spiritual
        growth, education, and feel a sense of belonging. Our work is guided by
        a sense of social responsibility and a deep desire to uplift and empower
        the entire community, including the students who are an integral part of
        it.
        <br />
        <br />
        Our mission is to address the diverse needs of our community, with a
        focus on both spiritual and social well-being. We provide essential
        facilities for education, recreation, and leisure activities, ensuring
        that everyone has the opportunity to thrive.
        <br />
        <br />
        Originally founded to offer a welcoming prayer space and a place for
        iftar for students in Swansea, Beacon Institute has since grown to
        support the broader community. While we continue to honor our roots by
        supporting students, we are equally committed to serving the wider
        community. We ensure that all who come through our doors, whether
        residents or students, find a place where they can gather, pray, and
        feel at home.
        <br />
        <br />
        As we continue to grow and evolve, we remain open to exploring other
        charitable initiatives that align with our mission. Guided by our
        Trustees, The Beacon Hub is committed to being a beacon of
        hope, support, and unity for the entire community.
      </p>
    </div>
  );
};

export default AboutUs;
